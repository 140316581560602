<template>
	<a-modal width="50%" title="添加图层" :visible="visible" @ok="handleOk"
    :confirmLoading="confirmLoading"
		@cancel="handleCancel">
    <a-alert message="注意使用WGS-84地心坐标系" type="warning" show-icon style="margin-bottom: 10px;" />
    <ax-form
      ref="formBox"
      :formBuilder="formBuilder"
      @change="onValueChange"
    >
      <div slot="fileId">
        <uploadFileSimpis
          ref="uploadFileSimp"
          :size="'10'"
          title="上传"
          :fileId="fileId"
          accept="tif,shp"
          multiple
        />
      </div>
      <div slot="style">
        <uploadFileSimpis
          ref="styleFileSimp"
          :size="'1'"
          title="上传"
          :fileId="style"
          accept=".json"
          multiple
        />
      </div>
    </ax-form>
    <!-- <img v-if="image" :src="image" style="height: 500px;" /> -->
	</a-modal>
</template>

<script>
import { initGridFormData, getGuid } from '@/common/tools'
import uploadFileSimpis from '@/components/uploadFileSimpis/index.vue'
import api from '../api.js'
import { getByDicKind } from '@/common/utils'
import { getData } from '@/common/tif.js'

export default {
  components: {
    uploadFileSimpis
  },
  props: {
    categoryId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      fileId: getGuid(),
      style: getGuid(),
      confirmLoading: false,
      // image: '',
      formBuilder: initGridFormData([
        {
          label: '文件名称',
          type: 'input',
          model: 'name',
          col: { span: 24 },
          options: {},
          rules: [{ required: true, message: '文件名称不能为空' }]
        },
        {
          label: '图层类型',
          type: 'select',
          model: 'type',
          options: { options: [{ label: '文件', value: 'male' }, { label: '接口', value: 'female' }] },
          col: { span: 24 },
          rules: [{ required: true, message: '图层类型不能为空' }]
        },
        {
          label: '图层文件',
          type: 'input',
          model: 'fileId',
          col: { span: 24 },
          options: { hide: true },
          rules: [{ required: true, message: '图层文件不能为空' }]
        },
        {
          label: '图例',
          type: 'input',
          model: 'style',
          col: { span: 24 },
          options: { hide: true },
          rules: [{ required: true, message: '图层文件不能为空' }]
        },
        {
          label: '年份',
          type: 'yearPicker',
          model: 'yearTime',
          options: { showTime: true, hide: true },
          col: { span: 24 },
          rules: [{ required: true, message: '年份不能为空' }]
        },
        {
          label: '接口地址',
          type: 'input',
          model: 'url',
          col: { span: 24 },
          options: { showTime: true, hide: true },
          rules: [{ required: true, message: '接口地址不能为空' }]
        },
        {
          label: '说明',
          type: 'textarea',
          model: 'remarks',
          options: { minRows: 4, maxRows: 4 },
          col: { span: 24 }
        }
      ])
    }
  },
  mounted () {
  },
  methods: {
    open (record) {
      this.visible = true
      this.fileId = getGuid()
      this.style = getGuid()
      setTimeout(() => {
        this.getTypeKind()
        if (record) {
          if (record.type === '01') {
            this.$refs.formBox.setOptions(['fileId', 'yearTime', 'style'], 'hide', false)
            this.$refs.formBox.setOptions(['url'], 'hide', true)
          } else if (record.type === '02') {
            this.$refs.formBox.setOptions(['url'], 'hide', false)
            this.$refs.formBox.setOptions(['fileId', 'yearTime', 'style'], 'hide', true)
          }
          this.$refs.formBox.setFieldsValue(record)
          this.fileId = record.fileId || getGuid()
          this.style = record.style || getGuid()
        }
      }, 500)
    },

    // 获取类型
    async getTypeKind () {
      const datas = await getByDicKind('shp_type')
      this.$refs.formBox.setOptions(['type'], 'options', datas)
    },

    // 添加
    async add (value) {
      try {
        const res = await api.baseadd({ ...value, categoryId: this.categoryId })
        if (res.status === 200) {
          this.confirmLoading = false
          this.$emit('on-ok', value)
          this.handleCancel()
        } else {
          this.confirmLoading = false
        }
      } catch {
        this.confirmLoading = false
      }
    },

    isindexOf (url, filename) {
      return url.find(item => item.toLowerCase().indexOf(filename) !== -1)
    },

    // 判断是 shp 还是 tif 文件
    isShpOrTif (url) {
      // shp
      const shpUrl = this.isindexOf(url, '.shp')
      const dbfUrl = this.isindexOf(url, '.dbf')
      // tif
      const tifUrl = this.isindexOf(url, '.tif')

      if (!shpUrl && !dbfUrl && !tifUrl) {
        this.$message.error('请上传shp或tif文件')
        return false
      }

      if (shpUrl && tifUrl) {
        this.$message.error('不能同时上传shp文件和tif文件')
        return false
      }

      if (tifUrl) {
        if (url.length > 1) {
          this.$message.error('上传tif文件时，只能上传一个附件')
          return false
        }
        return { tifUrl }
      }

      if (!shpUrl || !dbfUrl) {
        this.$message.error('shp文件需同时上传.shp文件和.dbf文件')
        return false
      }

      return { shpUrl, dbfUrl }
    },

    handleOk () {
      this.$refs.formBox.form.validateFields(async (errors, values) => {
        if (!errors) {
          const value = this.$refs.formBox.getFieldsValue()

          if (value.type === '01') {
            // json 数据
            const stylefileData = this.$refs.styleFileSimp.getAllFileList()
            if (stylefileData && stylefileData.length > 0) {
              const styleUrl = stylefileData.map(item => {
                return item?.response?.data?.url
              })
              value.style = this.style
              value.styleUrl = styleUrl[0]

              // this.getJSON(styleUrl[0])
            } else {
              value.style = ''
              value.styleUrl = ''
            }

            // shp 或者 tif 文件
            const fileData = this.$refs.uploadFileSimp.getAllFileList()
            if (!fileData || fileData.length <= 0) {
              this.$message.error('请上传文件')
              return
            }

            const url = fileData.map(item => {
              return item?.response?.data?.url
            })

            value.url = JSON.stringify(url)
            value.fileId = this.fileId

            const tifOrShp = this.isShpOrTif(url)

            if (!tifOrShp) {
              return
            }

            const { tifUrl } = tifOrShp
            this.confirmLoading = true
            if (tifUrl) {
              getData(tifUrl, value.yearTime, async (data) => {
                // value.tif = { ...data }
                // this.image = data.image
                if (data) {
                  const mytif = await this.handleUpload(data)
                  value.mytif = mytif
                }

                this.add(value)
              })
              return
            }
            this.add(value)
            return
          }
          this.confirmLoading = true
          this.add(value)
        }
      })
    },
    handleCancel () {
      const data = this.$refs.formBox.getFieldsValue()
      Object.keys(data).forEach(item => {
        data[item] = ''
      })
      this.$refs.formBox.setFieldsValue(data)
      this.$refs.formBox.setOptions(['fileId', 'yearTime', 'url', 'style'], 'hide', true)
      this.visible = false
    },
    onValueChange (value, key) {
      if (key === 'type') {
        if (value === '01') {
          this.$refs.formBox.setOptions(['fileId', 'yearTime', 'style'], 'hide', false)
          this.$refs.formBox.setOptions(['url'], 'hide', true)
        } else if (value === '02') {
          this.$refs.formBox.setOptions(['url'], 'hide', false)
          this.$refs.formBox.setOptions(['fileId', 'yearTime', 'style'], 'hide', true)
        } else {
          this.$refs.formBox.setOptions(['fileId', 'yearTime', 'url', 'style'], 'hide', true)
        }
      }
    },

    async handleUpload (data) {
      const fileData = new Blob([JSON.stringify(data)], { type: 'text/plain;charset=utf-8' })
      const fileContent = new File([fileData], 'JSON.mytif', { type: '' })
      const formData = new FormData()
      formData.append('file', fileContent)
      const res = await api.uploadFile(formData)
      if (res.status === 200) {
        return res?.data?.url
      } else {
        return ''
      }
    }

    // getJSON (url) {
    //   fetch(url)
    //     .then((response) => response.json())
    //     .then((data) => console.log(data, 'data'))
    // }
  }
}
</script>

<style>

</style>
