import * as GeoTIFF from 'geotiff'

const getImage = async (image, year, callback = () => {}) => {
  // const origin = image.getOrigin()
  const [west, south, east, north] = image.getBoundingBox()

  const code = image.geoKeys.ProjectedCSTypeGeoKey || image.geoKeys.GeographicTypeGeoKey

  image.readRasters({}).then((data) => {
    const rgba = data[0]

    const fun = () => {
      const canvas = document.createElement('canvas')
      const width = image.getWidth()
      const height = image.getHeight()
      canvas.width = width / 4
      canvas.height = height / 4
      const ctx = canvas.getContext('2d')
      const imageData = ctx.createImageData(width, height)
      for (var i = 0; i < imageData.data.length / 4; i += 1) {
        // if (parseInt(year) >= 2022) {
        imageData.data[i * 4 + 0] = rgba[i * 4] / 100
        imageData.data[i * 4 + 1] = rgba[i * 4 + 1] / 100
        imageData.data[i * 4 + 2] = rgba[i * 4 + 2] / 100
        // } else {
        //   imageData.data[i * 4 + 0] = rgba[i * 4]
        //   imageData.data[i * 4 + 1] = rgba[i * 4 + 1]
        //   imageData.data[i * 4 + 2] = rgba[i * 4 + 2]
        // }
        imageData.data[i * 4 + 3] = rgba[i * 4 + 3]
      }
      ctx.putImageData(imageData, 0, 0)
      const canvasImage = canvas.toDataURL('image/png')
      return canvasImage
    }
    if (code === 4326) {
      const canvasImage = fun()
      // eslint-disable-next-line standard/no-callback-literal
      callback({ image: canvasImage, code, extent: [west, north, east, south], year })
    } else if (code === 32649) {
      // const westnorth = EPSG.CGCS2000ToWGS84(west, north)
      // west = westnorth[0]
      // north = westnorth[1]
      // const eastsouth = EPSG.CGCS2000ToWGS84(east, south)
      // east = eastsouth[0]
      // south = eastsouth[1]
      const canvasImage = fun()
      // const offset = 8.999147138699755
      // eslint-disable-next-line standard/no-callback-literal
      callback({ extent: [west, north, east, south], image: canvasImage, code, year })
    } else {
      console.log('%c当前坐标系:EPSG:' + code + ' ' + '请使用ESPG:4326坐标系或者32649坐标系', 'color: red')
      callback(null)
    }
  })
}

const getData = async (url, year, callback = () => {}) => {
  fetch(url).then(res => res.arrayBuffer()).then(arrayBuffer => {
    GeoTIFF.fromArrayBuffer(arrayBuffer).then((tiff) => {
      return tiff.getImage()
    }).then((image) => {
      getImage(image, year, callback)
    })
  })
}

export {
  getData
}
