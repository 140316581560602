<template>
  <div>
    <div v-if="title==='预览'">
      <div
        v-for="item in fileList"
        :key="item.id"
        class="uploadInfo"
      >
        <a
          href="#"
          @click="downloadFile(item)"
        >{{ item.name }}</a>
      </div>
    </div>
    <div v-else>
      <div class="ellipsis">
        <a-upload
          v-bind="uploadProps"
          :file-list="fileList"
          :before-upload="beforeUpload"
          @change="onFileChange"
        >
          <a-button type="primary">
            {{ title }}
          </a-button>
          <p style="color:red;font-size:12px;">只能上传{{ this.accept }}文件</p>
        </a-upload>
      </div>
    </div>
  </div>
</template>

<script>
import api from './api'
import cookieUtil from '@/common/cookie-util'
import { isEmpty } from '../../common/tools'
export default {
  props: {
    title: {
      type: String,
      default: '上传'
    },
    accept: {
      type: String,
      default: '.pdf'
    },
    fileId: {
      type: String,
      default: ''
    },
    size: {
      type: [String, Number],
      default: '1'
    },
    multiple: { // 是否支持回显
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileList: []
    }
  },
  watch: {
    fileId: {
      handler (value) {
        if (value) {
          this.fileList = []
          this.getFileData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    uploadProps () {
      const { uploadProps } = this.$ax.config.uploadModal
      return {
        ...uploadProps,
        data: { bizType: 'contract', bizId: this.fileId },
        headers: { Authorization: cookieUtil.getToken() },
        action: `${process.env.VUE_APP_BASE_URL}/file/upload`,
        showUploadList: true,
        showAccept: true,
        accept: this.accept,
        multiple: this.multiple
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    getFileData () {
      api.getFileApi(this.fileId).then(res => {
        this.defaultFileList(res?.data)
      })
    },

    beforeUpload (file, fileList) {
      var fileSize = 3
      if (!isEmpty(this.size)) {
        fileSize = parseInt(this.size)
      }
      if (fileList.length > fileSize) {
        this.$message.error('只能上传' + fileSize + '个文件')
        return false
      }
      return true
    },

    /** 文件列表 */
    defaultFileList (fileData = []) {
      if (fileData.length <= 0) {
        this.fileList = []
      } else {
        this.fileList = fileData.map((item) => {
          return {
            uid: item.id,
            name: item.fileName,
            status: 'done',
            response: {
              data: {
                ...item
              }
            },
            url: item.url
          }
        })
      }
    },

    /**
     * 文件改变
     */
    async onFileChange ({ file, fileList }) {
      if (file.status === 'removed') {
        this.$confirm({
          title: '系统提示',
          content: '确定删除该附件吗？',
          onOk: () => {
            api.deleteFileApi(file?.response?.data?.id)
              .then(() => {
                const fileList = this.fileList.filter(item => {
                  return item.response.data.id !== file.response.data.id
                })
                this.fileList = [...fileList]
              })
              .catch((e) => {
                console.log(e)
              })
          }
        })
      } else {
        this.fileList = fileList
      }
    },

    getFileList () {
      return (this.fileList.length > 0 && this.fileList[0]) || []
    },

    getAllFileList () {
      return (this.fileList.length > 0 && this.fileList) || []
    },

    // 下载
    downloadFile (item) {
      if (item.url.indexOf('.pdf') !== -1) {
        window.open(process.env.VUE_APP_BASE_URL + `/file/previewPDF?id=${item.uid}`, '_blank')
        return
      }
      window.open(
        `${process.env.VUE_APP_BASE_URL}/file/download/?id=${item.uid}`
      )
    }
  }
}
</script>

<style scoped>
.uploadInfo {
  line-height: 2;
  padding: 0 10px;
  word-break: keep-all;
  white-space: nowrap;
}
</style>
